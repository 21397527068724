import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../../styles/recruit_works.module.scss"
import iconMan01 from "../../images/recruit-works-icon-man.svg"
import iconMan02 from "../../images/recruit-works-icon-man2.svg"
import iconWoman from "../../images/recruit-works-icon-woman.svg"

const Work = () => {
  return (
    <Layout>
      <Seo
        title="職種紹介 | Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <div id="recruit">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>RECRUIT</h1>
            <h2 className={style.catch}>職種紹介</h2>
          </div>
        </div>
      </div>
      <div className={style.contentsWrap}>
        <div className={style.message}>
          <h2>
            消費者目線で
            <br className={style.hiddenPC} />
            モノゴトを考える仕事
          </h2>
          <p>
            所属部署に関わらず、消費者が本当に必要としているモノ・サービスとは何かを常に考えて行動する集団がアローズです。
          </p>
        </div>
        <section className={style.works}>
          <div className={style.item}>
            <h2>企画・営業</h2>
            <div className={style.itemWrap}>
              <div className={style.itemImg}>
                <StaticImage
                  src="../../images/recruit-works-pic01.png"
                  alt="企画・営業"
                  quality={90}
                  placeholder="dominantColor"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  layout="constrained"
                />
              </div>
              <div className={style.itemCont}>
                <p>
                  消費者が欲しがっているものを提供できているか？競合他社よりも圧倒的に魅力ある商品をバイヤーに提案できているか？「提案力」、「情報収集力」そして「人間力」の３つの要素が求められるのが企画・営業チームです。
                  <br />
                  （求められる人物像）年齢や社歴に関係なく上司や先輩とも対等に意見交換できるフラットな社風です。自分の意見を持って相手に伝えられる方、自分のアイデアを積極的に発信できる方は楽しく仕事ができます。
                </p>
                <div className={style.wantPerson}>
                  <div className={style.personImg}>
                    <p>
                      <img src={iconMan01} alt="求める人物像アイコン" />
                    </p>
                  </div>
                  <div className={style.personCont}>
                    <p>
                      <b>求める人物像</b>
                    </p>
                    <p className={style.personTxt}>
                      ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー
                      <br />
                      ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.item}>
            <h2>EC事業部</h2>
            <div className={style.itemWrap}>
              <div className={style.itemImg}>
                <StaticImage
                  src="../../images/recruit-works-pic02.png"
                  alt="商品開発"
                  quality={90}
                  placeholder="dominantColor"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  layout="constrained"
                />
              </div>
              <div className={style.itemCont}>
                <p>
                  消費者に直接商品を販売するEC事業部。消費者の生の声をダイレクトに聞くことが出来る事が最大の強みです。スマートフォンケース、コンタクトレンズに留まることなく、消費者に求められる商品を積極的に開発・仕入しています。
                  <br />
                  （求められる人物像）新しい商品、情報、トレンドに敏感で、キャッチアップしていく柔軟さが求められます。自分の仕事の枠を限定せずに、積極的に新しい事にチャレンジしていける人は活躍できます。
                </p>
                <div className={style.wantPerson}>
                  <div className={style.personImg}>
                    <p>
                      <img src={iconWoman} alt="求める人物像アイコン" />
                    </p>
                  </div>
                  <div className={style.personCont}>
                    <p>
                      <b>求める人物像</b>
                    </p>
                    <p>
                      ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー
                      <br />
                      ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.item}>
            <h2>総務・管理</h2>
            <div className={style.itemWrap}>
              <div className={style.itemImg}>
                <StaticImage
                  src="../../images/recruit-works-pic03.png"
                  alt="総務・管理"
                  quality={90}
                  placeholder="dominantColor"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  layout="constrained"
                />
              </div>
              <div className={style.itemCont}>
                <p>
                  アローズの縁の下の力持ち、総務・経理部。全社員が安心して働けるのも、彼らの正確無比なパフォーマンスがあるからです。
                  <br />
                  （求められる人物像）物事を一つ一つスピーディーにかつ正確に処理する力のある人にむいています。経理部では決算業務に至るまでの幅広い知識と経験を身に着ける事ができるのもこの仕事の魅力です。
                </p>
                <div className={style.wantPerson}>
                  <div className={style.personImg}>
                    <p>
                      <img src={iconMan02} alt="求める人物像アイコン" />
                    </p>
                  </div>
                  <div className={style.personCont}>
                    <p>
                      <b>求める人物像</b>
                    </p>
                    <p>
                      ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー
                      <br />
                      ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.entryBox}>
            <h3>
              アローズの募集要項・
              <br className={style.hiddenPC} />
              エントリーはこちら
            </h3>
            <div className={style.entryBoxInner}>
              <div className={style.rikunavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../../images/recruit-bnr01.png"
                    alt="リクナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={199}
                    height={44}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  リクナビからエントリー
                </a>
              </div>
              <div className={style.mynavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../../images/recruit-bnr02.png"
                    alt="マイナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={172}
                    height={41}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  マイナビからエントリー
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Work
